import React from 'react';

import DemoConfirmationContainer from '@containers/demoConfirmation';
import useDemoConfirmationQuery from '@graphql/queries/DemoConfirmation';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface DemoConfirmationProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: DemoConfirmationProps) => {
  const data = useDemoConfirmationQuery()[props.pageContext.langcode];

  return (
    <>
      <MetaPage
        title={data.demoConfirmationData.seo.title}
        description={data.demoConfirmationData.seo.description}
      />
      <DataLayer location={props.location} />
      <DemoConfirmationContainer
        {...data}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
