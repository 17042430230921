import React, { Component } from 'react';
import { DemoConfirmationQueryProps } from '@graphql/queries/DemoConfirmation';
import Page from '@components/layout/Page';
import Hero from '@components/common/Hero';
import * as utils from '@utils';

export interface DemoConfirmationProps extends DemoConfirmationQueryProps {
  langcode: string;
  urls: Array<string>;
}

class DemoConfirmationComponent extends Component<DemoConfirmationProps> {
  render() {
    const { demoConfirmationData, langcode, urls } = this.props;

    return (
      <Page>
        <Hero
          size="medium"
          image={demoConfirmationData.heroImage}
          noImageOnMobile={true}
          paragraph={utils.SafeHtml(demoConfirmationData.paragraph)}
          buttonText={demoConfirmationData.button}
          buttonRoute={utils.langLink(langcode, urls[20])}
          text="dark"
          noArrow={true}
        />
      </Page>
    );
  }
}

export default DemoConfirmationComponent;
