import get from 'lodash.get';

export const demoConfirmationTransformers = page => {
  const demoConfirmationLanguages = {};

  page.allSiteSettingEntityThankYouPages.edges.forEach(edge => {
    const data = edge.node;

    demoConfirmationLanguages[data.langcode] = {
      demoConfirmationData: {
        heroImage: get(page, 'configuratorConfHero.childImageSharp', null),
        button: get(data, 'field_settings_button_text', ''),
        paragraph: get(data, 'field_settings_tresc.value', ''),
        seo: {
          title: get(data, 'field_meta_title_1', ''),
          description: get(data, 'field_meta_description_1', ''),
        },
      },
    };
  });

  return demoConfirmationLanguages;
};
