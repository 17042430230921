import { graphql, useStaticQuery } from 'gatsby';
import { DemoConfirmationDataProps } from '@content/types/demoConfirmation';
import * as demoConfirmationTransformers from '@content/transformers/DemoConfirmationTransformers';

export interface DemoConfirmationQueryProps {
  demoConfirmationData: DemoConfirmationDataProps;
}

const DemoConfirmationQuery = (): DemoConfirmationQueryProps => {
  const demoConfirmationQuery = useStaticQuery(graphql`
    query {
      configuratorConfHero: file(relativePath: { eq: "configurator-thankyoupage-hero@2x.jpg" }) {
        childImageSharp {
          fixed(width: 1600, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      allSiteSettingEntityThankYouPages {
        edges {
          node {
            langcode
            field_settings_button_text
            field_settings_tresc {
              value
            }
            field_meta_title_1
            field_meta_description_1
          }
        }
      }
    }
  `);

  return demoConfirmationTransformers.demoConfirmationTransformers(demoConfirmationQuery);
};

export default DemoConfirmationQuery;
