import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import DemoConfirmationComponent, { DemoConfirmationProps } from '@components/demoConfirmation';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DDemoConfirmationPropsProps extends DemoConfirmationProps {}
const DDemoConfirmation: React.FC<DDemoConfirmationPropsProps> = (
  props: DDemoConfirmationPropsProps
) => <DemoConfirmationComponent {...props} />;

const DemoConfirmationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DDemoConfirmation);

export default DemoConfirmationConnected;
